<script>
import { ref, reactive, computed, onMounted } from 'vue'
import { nanoid } from 'nanoid'
import { http } from '@/http'
import { useRouter } from 'vue-router'
import { browser,regFenToYuan } from '@/utils'
import BigNumber from 'bignumber.js'
import channelStepBar from '@/components/channel-step-bar'

export default {
  components: {
    channelStepBar,
  },

  setup() {
    let router = useRouter()
    let serviceInfo = reactive({})
    let currentIndex = ref(0)
    let orderInfo = reactive({})
    let showSheet = ref(false)
    let idRef = ref(null)
    function onChange(index) {
      currentIndex.value = index
    }

    function getService() {
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerTaxCommissionRecordService.getService', {
        id: 1
      }).then(res => {
        for(let item in res.result) {
          serviceInfo[item] = res.result[item]
        }
      })
    }

    function createServiceOrder() {
      return http.post('/?cmd=com.xqxc.api.pigear.biz.order.CreateOrderService.createServiceOrder', {
        goodsId: serviceInfo.id
      })
    }

    function handleClickOrderPay() {
      createServiceOrder().then(res => {
        for(let item in res.result) {
          orderInfo[item] = res.result[item]
        }

        if(orderInfo.channelList && orderInfo.channelList.length) {
          orderInfo.channelList = orderInfo.channelList
            .map((item, i) => {
              return {
                ...item,
                name: item.value
              }
            })
        }
        showSheet.value = true 
      })
      
    }


    function openLink(options) {
      let url = router.resolve({
        path: '/pay',
        query: {
          orderId: options.orderId,
          orderType: options.orderType,
          sourceType: 'H5',
          device: 'H5',
          accessType: options.accessType,
          channelType:options.channelType,
          payTimeout: options.payTimeout,
          payPrice: options.payPrice,
          discount: options.discount,
          value: options.value
        }
      })
      window.open(url.href, '_blank')
    }

    function onSelect(item) {
      let id = nanoid()
      router.push(`/pay?id=pay_${id}`)

      // openLink({
      //   orderId: orderInfo.orderId,
      //   orderType: orderInfo.orderType,
      //   sourceType: 'H5',
      //   device: 'H5',
      //   accessType: item.accessType,
      //   channelType:item.code,
      //   payTimeout: orderInfo.payTimeout.replace(/\-/g, '/'),
      //   payPrice: BigNumber(orderInfo.payPrice).div(100),
      //   discount: BigNumber(orderInfo.discount).div(100),
      //   value: item.value
      // })
      window.sessionStorage.setItem(`pay_${id}`, JSON.stringify({
        orderId: orderInfo.orderId,
        orderType: orderInfo.orderType,
        sourceType:'H5',
        device:'H5',
        accessType: item.accessType,
        channelType:item.code,
        payTimeout:orderInfo.payTimeout.replace(/\-/g, '/'),
        value: item.value,
        payPrice: regFenToYuan(orderInfo.payPrice),
        discount: regFenToYuan(orderInfo.discount)
      }))
      let filterKey = Object.keys(sessionStorage)
        .filter(item => item.startsWith('pay_'))
        .filter(item => item !== `pay_${id}`)[0]
      sessionStorage.removeItem(filterKey)
      
      //router.push(`/pay?orderId=${orderInfo.orderId}&orderType=${orderInfo.orderType}&sourceType=H5&device=H5&accessType=${item.accessType}&channelType=${item.code}&payTimeout=${orderInfo.payTimeout}`)
    }
  
    onMounted(getService)

    return {
      serviceInfo,
      currentIndex,
      orderInfo,
      showSheet,
      onChange,
      onSelect,
      handleClickOrderPay
    }
  }
}
</script>
<template>
  <div class="page">
    <channel-step-bar />
    <van-swipe @change="onChange" :autoplay="5000" lazy-render>
      <van-swipe-item v-for="item in serviceInfo.pics" :key="item">
        <img class="image" :src="item" />
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">{{ currentIndex + 1 }}/{{serviceInfo.pics && serviceInfo.pics.length}}</div>
      </template>
    </van-swipe>
    <div class="shop-info">
      <p class="shop-price">¥{{serviceInfo.priceStr}}</p>
      <p class="shop-name">{{serviceInfo.name}}</p>
    </div>
    <div>
      <h4>商品介绍</h4>
      <div class="info-content" v-html="serviceInfo.introInfo" />
    </div>
    
    <div class="btn-ct">
      <van-button block type="primary" @click="handleClickOrderPay">立即开通</van-button>
    </div>
    <van-action-sheet
      v-model:show="showSheet"
      :actions="orderInfo.channelList"
      :round="false"
      cancel-text="取消"
      @select="onSelect"
      close-on-click-action
    />
  </div>
</template>
<style>
    h4 {text-align: center; color: #666}
    .shop-info {padding: .34rem}
    .shop-price { margin-bottom: .3rem;color: #EC0909; font-size: .5rem}
    .shop-name {font-size:.4rem; line-height:1.3}
    .image {height: 3.7rem; width: 100%}
    .info-content { padding: 1rem .34rem;text-align:justify;}
  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    color: #fff;
    background: rgba(0, 0, 0, 0.1);
  }
  .btn-ct { background: #fff; padding: .3rem; box-sizing: border-box;position: fixed; width: 100%; bottom: 0}
  .van-action-sheet__gap {
    background: #f0f0f0;
  }
</style>